import styled from "styled-components";
import { Image } from "@/components/image";
import { COLORS } from "@/constants/styles";
import { useSelector } from "@/redux";
import { getS3Path } from "@/utils/s3";

export function VgOrderConfirmationFooter() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledOrderDetailReward>
      <div>
        <div>
          <h1>Sign Up for Veggie Grill Rewards!</h1>
          <p>
            You&apos;ll earn $9 for every $99 spent. Download the Veggie Grill
            app to view your progress.
          </p>
          <div className="appIcons">
            <a
              href="https://itunes.apple.com/us/app/vg-rewards/id1329899808?ls=1&mt=8"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="Download on the App Store"
                height={40}
                src={getS3Path(
                  slug,
                  "images/Download_on_the_App_Store_Badge_US-UK_135x40.svg"
                )}
                width={140}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ak.app.veggiegrill.activity"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                alt="Get it on Google Play"
                height={40}
                src={getS3Path(slug, "images/google-play-badge.png")}
                width={140}
              />
            </a>
          </div>
        </div>
        <div className="rightContent">
          <Image
            alt=""
            role="presentation"
            height={190}
            src={getS3Path(slug, "images/VG_Reward.png")}
            width={176}
          />
        </div>
      </div>
    </StyledOrderDetailReward>
  );
}

const StyledOrderDetailReward = styled.div`
  position: relative;
  padding: 30px 35px 0px;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.MEDIUMGRAY};
  box-shadow: 0 2px 0 0 ${COLORS.MEDIUMGRAY};
  text-align: left;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};

  h1 {
    font-size: 16px;
    margin-top: 24px;
  }

  p {
    font-family: ${({ theme }) => theme.primary_font.light.font_family};
    margin-bottom: 25px;
    font-size: 12px;
    padding-right: 30px;
    line-height: 20px;
  }

  > div {
    display: flex;
  }
  .appIcons {
    img {
      height: 40px;
      padding-right: 5px;
      margin-bottom: 20px;
      @media (max-width: 577px) {
        height: 38px;
      }
    }
  }
  .rightContent {
    margin-left: auto;
    span {
      vertical-align: bottom;
    }
    @media (max-width: 577px) {
      display: none;
    }
  }
`;
