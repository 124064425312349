import FocusTrap, { type Props } from "focus-trap-react";
import { useEffect, useState, useRef } from "react";
import GenericErrorBoundary from "@/components/genericErrorBoundary";

/**
 * Issue: https://github.com/focus-trap/focus-trap-react/issues/20
 * See PR for more details: https://github.com/fuzz-productions/koala-ordering-webapp/pull/805
 *
 * Usage rules:
 *
 * 1. Do not nest a focus trap in a parent with visibility: hidden and also give the children
 *    visibility: hidden when basing this CSS property value off of a prop. Focus traps
 *    must be parents of elements with varying visibility.
 * 2. Do not use the same prop for every visibility: hidden || visible. If you need more than
 *    one nested visibility rule, the HTML structure is most likely worth re-thinking.
 * 3. Every active focus trap MUST have a tabbable element. There is no exception to this rule.
 *    If it does not have a focusable element, it is not semantic HTML and the application will break.
 */

export const GenericFocusTrap = (props: Props) => {
  const { active, ...otherProps } = props;

  const [activateTrap, setActivateTrap] = useState<boolean>(false);
  const childrenContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!active) {
      setActivateTrap(false);
    }

    if (
      !childrenContainer.current ||
      !window?.IntersectionObserver ||
      active === activateTrap
    ) {
      return;
    }

    const tabbableElement = childrenContainer.current.querySelector(
      'button, a[href], input, select, textarea, audio[controls], video[controls], [contenteditable], form:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])'
    );

    if (!tabbableElement) {
      return;
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && active) {
          setActivateTrap(true);
          observer.unobserve(tabbableElement);
        }
      });
    });

    observer.observe(tabbableElement);

    return () => {
      observer.disconnect();
    };
  }, [active]);

  return (
    <GenericErrorBoundary>
      <div ref={childrenContainer}>
        <FocusTrap active={activateTrap} {...otherProps}>
          {props.children}
        </FocusTrap>
      </div>
    </GenericErrorBoundary>
  );
};
