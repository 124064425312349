import { Component } from "react";
import {
  StyledFlexContainer,
  StyledGenericModal,
  StyledGenericModalContainer,
  StyledGenericModalHeader,
} from "./styles";
import { pageViewEventHandler } from "@/analytics/events";
import { ConnectedXButton } from "@/components/uielements/X";
import { GenericFocusTrap } from "@/components/uielements/genericFocusTrap";

interface Props {
  modalOpen: boolean;
  name: string;
  toggleModal?: (show: boolean) => void;
  header?: string | JSX.Element;
  maxWidth?: number;
  confirmMessage?: any;
  cancelMessage?: any;
  disableModalClose?: boolean;
  onClose?: () => void;
}

interface State {
  height: number | null;
}

export default class GenericModal extends Component<Props> {
  state: State = {
    height: null,
  };

  componentDidMount() {
    this.setState({ height: window.innerHeight });

    document.addEventListener("keydown", this.close);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.close);
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate({ modalOpen, name }: Props) {
    // Fire KA event if modal is open
    if (this.props.modalOpen !== modalOpen && this.props.modalOpen) {
      // Consolidated pageview events
      pageViewEventHandler(name);
    }
  }

  handleResize = () => {
    this.setState({ height: window.innerHeight });
  };

  close = (evt: { keyCode: number }) => {
    const { toggleModal, disableModalClose, onClose } = this.props;

    // Close modal on 'esc' press
    if (evt.keyCode === 27 && toggleModal && !disableModalClose) {
      toggleModal(false);
      onClose && onClose();
    }
  };

  render() {
    const {
      children,
      modalOpen,
      header,
      maxWidth,
      name,
      toggleModal,
      disableModalClose,
    } = this.props;
    const { height } = this.state;

    if (!modalOpen) {
      return <span />;
    }

    return (
      <GenericFocusTrap
        focusTrapOptions={{
          clickOutsideDeactivates: !disableModalClose,
        }}
      >
        <StyledGenericModal>
          <StyledGenericModalContainer maxWidth={maxWidth} height={height}>
            <div>
              {header && (
                <StyledFlexContainer header>
                  <StyledGenericModalHeader tabIndex={0}>
                    {typeof header === "string" ? (
                      <p>{header}</p>
                    ) : (
                      <>{header}</>
                    )}
                  </StyledGenericModalHeader>
                </StyledFlexContainer>
              )}

              {toggleModal && !disableModalClose && (
                <ConnectedXButton
                  onClick={() => toggleModal(false)}
                  name={name}
                />
              )}
            </div>

            {children}
          </StyledGenericModalContainer>
        </StyledGenericModal>
      </GenericFocusTrap>
    );
  }
}
