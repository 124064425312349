/*
 * Colors
 */
const WHITE = "#FFF";
const BLACK = "#000";
const DARKBLUE = "#303E47";
const GRAY = "#C5C7C8";
const LIGHTGRAY = "#EDEDED";
const PRIMARY_NAVY = "#313d48";
const PRIMARY_NAVY_LIGHT = "#989ea3";
const PRIMARY_NAVY_X_LIGHT = "#cbced1";
const PRIMARY_GREEN_LIGHT = "#bfe1a3";
const PRIMARY_GREEN_X_LIGHT = "#dff0d1";
const PRIMARY_NAVY_XX_LIGHT = "#f4f5f6";
const PRIMARY_ORANGE = "#f68000";
const PRIMARY_ORANGE_LIGHT = "#f9bc95";
const PRIMARY_ORANGE_X_LIGHT = "#fcddca";
const ERROR_RED = "#ff2d44";
const ERROR_RED_LIGHT = "#ff96a1";
const ERROR_RED_DARK = "#d0021b";
const ERROR_RED_X_LIGHT = "#ffcad0";
const ERROR_RED_BACKGROUND = "#fae1e0";
const WARNING = "#fb6100";
const DARKGRAY = "#666666";
const DARKERGRAY = "#979ea3";
const MEDIUMGRAY = "#E6E8EA";
const SHADOWGRAY = "#F4F5F6";
const HOVERGRAY = "#ededed";
const LIGHTERGRAY = "#cbcfd1";

const BACKGROUNDGRAY = "#eff3f7";

export const COLORS = {
  PRIMARY: DARKBLUE,
  BODY: WHITE,
  CONTENT: WHITE,
  HEADER: WHITE,
  TEXT: DARKBLUE,
  SEMI_TRANSPARENT: "rgba(0, 0, 0, 0.4)",

  DARKBLUE,
  DARKERGRAY,
  HOVERGRAY,
  GRAY,
  LIGHTGRAY,
  WHITE,
  BLACK,
  PRIMARY_NAVY,
  PRIMARY_NAVY_LIGHT,
  PRIMARY_NAVY_X_LIGHT,
  PRIMARY_NAVY_XX_LIGHT,
  PRIMARY_GREEN_LIGHT,
  PRIMARY_GREEN_X_LIGHT,
  PRIMARY_ORANGE,
  PRIMARY_ORANGE_LIGHT,
  PRIMARY_ORANGE_X_LIGHT,
  ERROR_RED,
  ERROR_RED_LIGHT,
  ERROR_RED_DARK,
  ERROR_RED_X_LIGHT,
  ERROR_RED_BACKGROUND,
  MEDIUMGRAY,
  DARKGRAY,
  BACKGROUNDGRAY,
  SHADOWGRAY,
  LIGHTERGRAY,
  WARNING,
};

export const LAYOUT = {
  ACCOUNT_SIDEBAR_WIDTH: 250,
  MOBILE_BREAKPOINT: 768,
  HEADERHEIGHT: 80,
  STICKY_MENUHEIGHT_DESKTOP: 100,
  STICKY_MENUHEIGHT_MOBILE: 70,
  ITEM_HEADERHEIGHT: 68,
  MOBILE_HEADERHEIGHT: 50,
  MOBILE_ITEM_HEADERHEIGHT: 60,
  MOBILE_STORE_MENUHEIGHT: 65,
  LOYALTY_MENU_HEIGHT_DESKTOP: 80,
  LOYALTY_MENU_HEIGHT_MOBILE: 60,
  TOASTHEIGHT: 50,
  MAXWIDTH: 1440,
  GUTTER: 20,
  LINE_HEIGHT: 1.5,
  H_SPACING: 50,
  MEDIUM_CONTENT: 655,
  CUSTOMIZE_PRODUCT_DETAIL_WIDTH: 350,
  MAX_CHECKOUT_CONTAINER_WIDTH: 655,
  MAX_LOYALTY_CONTAINER_WIDTH: 1200,
  MOBILE_MAP_LOCATION_CARD_HEIGHT: 190,
  MOBILE_LIST_MAP_FOOTER_HEIGHT: 35,
  CUSTOMIZE_MODAL_MAX_WIDTH: 900,
  CUSTOMIZE_FOOTER_MAX_HEIGHT: 140,
  LEFT_CATEGORY_NAV_MIN_WIDTH: 200,
  LEFT_CATEGORY_NAV_MAX_WIDTH: 275,
  PRIMARY_BUTTON_LARGE_HEIGHT: 57,
  QUANTITY_SELECTOR_HEIGHT: 40,
  MENU_SEARCH_HEIGHT: 48,
  MENU_SEARCH_RESULT_HEIGHT: 68,
  NAV_BOTTOM_BORDER_WEIGHT: 1,
  GOOGLE_MAP_HEIGHT: 438,
  MAIN_CONTAINER_WIDTH: 1142,
};

export const SPACING_SCALE = {
  TWOXS: 4,
  THREEXS: 8,
  XS: 12,
  SMALL: 16,
  MEDIUM: 24,
  LARGE: 32,
  XL: 48,
  TWOXL: 64,
  THREEXL: 72,
};

export const GRID_SIZE = {
  ONE: "1x",
  TWO: "2x",
} as const;

/*
 * Order
 */
export const ORDER = {
  ACCOUNT_DROPDOWN: 22,
  PREVIEWER: 21,
  TOOLTIP: 20,
  TOAST: 19,
  TAKEOVER: 18,
  LOADING: 16,
  CUSTOMIZE: 14,
  MIGRATE_CART: 14,
  OVERLAY: 13,
  CART: 13,
  BARBER_LOADING: 12,
  SKIP_TO_CONTENT: 12,
  STORE_DETAIL: 11,
  HEADER: 11,
  LOYALTY_HEADER: 9,
  MENU: 9,
  MAP: 8,
  STORE_CARD_MENU: 8,
  MENU_SEARCH_RESULTS: 10,
  MENU_SEARCH_MODAL: 10,
  LOADING_SKELETON: 2,
};
