import styled from "styled-components";
import { Image } from "@/components/image";
import { LAYOUT } from "@/constants/styles";
import { useSelector } from "@/redux";
import { getS3Path } from "@/utils/s3";

export function CapitalTacosRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <StyledModalContent>
      <Image
        alt="Capital Rewards"
        height={150}
        src={getS3Path(slug, "images/capital-rewards.png")}
        width={133}
      />
      <h1>New Year, New Rewards. Always Done Right.</h1>

      <p>
        Now in 2023, we have new and exciting updates to Capital Rewards: More
        opportunities to redeem your points, and we will be dropping new
        exclusive rewards offers every month.
      </p>

      <p>
        1. Sign Up &#38; Score Immediately: Join Capital Rewards and receive
        Free Small Queso Bites with your first $10+ order!
      </p>
      <p>2. Order: Receive 1 rewards point with every dollar spent</p>
      <p>
        3. Unlock Rewards & More Now Ways to Redeem Points For Free Items - On
        Us:
      </p>

      <ul>
        <li>Small craft soda (35 points)</li>
        <li>Free small salsa (55 points)</li>
        <li>Small queso (75 Points)</li>
        <li>Small mini churros (100 Points)</li>
        <li>Mexican street corn (125 Points)</li>
        <li>Fries with Queso (150 Points)</li>
        <li>Big kahuna taco (175 Points)</li>
        <li>Chicken bacon ranchadilla (200 Points)</li>
        <li>Epic Nachos (250 Points)</li>
      </ul>
      <p>
        4. Stay In The Capital Know: Stay on top of your email for exclusive
        #TacoTuesday deals, new and secret menu items, and a whole lot more...
      </p>
      <p>Exclusive rewards offers every dropping every month</p>
      <p>
        Learn More About Capital Rewards{" "}
        <a
          href="https://www.capitaltacos.com/rewardsinfo"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div`
  margin: ${LAYOUT.GUTTER}px 0 0 0;
  padding: ${LAYOUT.GUTTER}px;
  text-align: center;

  img {
    margin: 0 auto;
    max-height: 150px;
  }

  h1 {
    font-size: 24px;
    color: #ef4b24;
    line-height: 110%;
    font-family: ${({ theme }) => theme.secondary_font.regular.font_family};
  }

  p {
    font-family: ${({ theme }) => theme.secondary_font.regular.font_family};
    font-size: 17px;
    color: #ef4b24;
  }

  ul {
    color: #ef4b24;
    font-family: ${({ theme }) => theme.secondary_font.regular.font_family};
    font-size: 17px;
    padding-left: 20px;
    text-align: left;
  }

  a {
    text-decoration: underline;
    color: #ef4b24;
  }
`;
