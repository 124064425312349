import { css } from "styled-components";

export const LinkHoverCss = css`
  text-decoration: underline;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 0.7;
  }
`;
