import styled from "styled-components";
import { LinkHoverCss } from "@/components/uielements/css";
import { StyledPrimaryButton } from "@/components/uielements/primaryButton/styles";
import { ORDER, LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const StyledGenericModal = styled.div`
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ORDER.MIGRATE_CART};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

interface StyledGenericModalContainerProps {
  maxWidth?: number;
  height?: number | null;
}

export const StyledGenericModalContainer =
  styled.div<StyledGenericModalContainerProps>(
    ({ theme, maxWidth, height }) => ({
      background: theme.modals.background_color ?? "white",
      border: `1px solid ${theme.modals.border_color ?? "transparent"}`,
      borderRadius: `${theme.modals.border_radius ?? 0}px`,
      boxShadow: "inset 0 -1px 0 0 #cccccc",
      overflowY: "scroll",
      maxHeight: "calc(100vh - 100px)",
      width: "450px",
      maxWidth: `${maxWidth ?? 500}px`,
      position: "relative",
      ...getFontStyles(theme.modals.font, ["color"]),

      "@media (max-width: 767px)": {
        maxHeight: `${height ? `${height}px` : "100%"}`,
        paddingBottom: "20px",
        width: "100%",
      },
    })
  );

export const StyledGenericModalHeader = styled.div(({ theme }) => ({
  padding: "30px",
  position: "relative",
  margin: "0 auto",
  ...getFontStyles(theme.modals.secondary_font),

  "h1, h2, h3, h4, p": {
    margin: 0,
    padding: 0,
  },
}));

export const StyledGenericModalContent = styled.div`
  @media (max-width: 767px) {
    flex: 1;
  }
`;

interface StyledFlexContainerProps {
  header?: boolean;
}

export const StyledFlexContainer = styled.div<StyledFlexContainerProps>`
  display: flex;
  position: relative;
  padding: ${LAYOUT.GUTTER / 2}px 0;

  ${({ header, theme }) =>
    header &&
    `
    border-bottom: solid 0.5px ${theme.modals.border_color ?? "transparent"};
    padding: 0;
  `}
`;

export const StyledGenericModalFooter = styled.div`
  background: ${({ theme }) =>
    theme.modals.footer_color ?? theme.modals.background_color};
  padding: ${LAYOUT.GUTTER}px;
  width: 100%;
`;

export const StyledAlertCta = styled(StyledPrimaryButton)`
  margin: 0 0 ${LAYOUT.GUTTER}px;
  width: 100%;
`;

export const StyledSecondaryButton = styled.button.attrs({
  "data-css-override": "StyledSecondaryButton",
})`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.modals.active_font_color};
  cursor: pointer;
  font-family: ${({ theme }) => theme.primary_font.medium.font_family};
  font-size: 16px;
  width: 100%;

  ${LinkHoverCss}
`;

export const StyledChangeLocation = styled.div`
  a {
    text-decoration: underline;
  }
`;
