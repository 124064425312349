import { CapitalTacosRewardsTerms } from "./capital-tacos";
import { DeepFloatingButton } from "./deepweb";
import { GraetersRewardsTerms } from "./graeters";
import { LunaGrillRewardsTerms } from "./luna-grill";
import { MooyahRewardsTerms } from "./mooyah";
import { PancherosRewardsTerms } from "./pancheros";
import { PfChangsRewardsTerms } from "./pfchangs";
import { PokeworksRewardsTerms } from "./pokeworks";
import { PolloCamperoRewardsTerms } from "./pollo-campero";
import { SalsaritasRewardsTerms } from "./salsaritas";
import { ScratchKitchenRewardsTerms } from "./scratch-kitchen";
import { VgOrderConfirmationFooter } from "./vg";

interface ComponentConfig {
  MODAL_HOW_REWARDS_WORK: Record<string, React.ElementType>;
  GLOBAL_FLOATING: Record<string, React.ElementType>;
  ORDER_CONFIRMATION_FOOTER: Record<string, React.ElementType>;
}

export const COMPONENT_CONFIG: ComponentConfig = {
  MODAL_HOW_REWARDS_WORK: {
    capitaltacos: CapitalTacosRewardsTerms,
    graeters: GraetersRewardsTerms,
    lunagrill: LunaGrillRewardsTerms,
    mooyah: MooyahRewardsTerms,
    pancheros: PancherosRewardsTerms,
    pfchangs: PfChangsRewardsTerms,
    pokeworks: PokeworksRewardsTerms,
    pollocampero: PolloCamperoRewardsTerms,
    salsaritas: SalsaritasRewardsTerms,
    salsaritascatering: SalsaritasRewardsTerms,
    scratchkitchen: ScratchKitchenRewardsTerms,
  },
  GLOBAL_FLOATING: {
    deepweb: DeepFloatingButton,
  },
  ORDER_CONFIRMATION_FOOTER: {
    "vg-catering": VgOrderConfirmationFooter,
  },
};
