import styled from "styled-components";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export function PancherosRewardsTerms() {
  return (
    <StyledModalContent>
      <h1>Pancheros Rewards</h1>
      <h2>Are you hungry? You will be!</h2>
      <ul>
        <li>
          <b>1. Order:</b> Build your burrito and order for pick-up or delivery.
        </li>
        <li>
          <b>2. Earn:</b> Get 1 point for every dollar you spend.
        </li>
        <li>
          <b>3. Eat:</b> After earning 100 points, get a Free Entree of your
          choice.
        </li>
      </ul>
      <p>Yes, it&apos;s that easy.</p>
    </StyledModalContent>
  );
}

const StyledModalContent = styled.div(({ theme }) => ({
  fontFamily: theme.primary_font.regular.font_family,
  margin: 0,
  padding: "20px",
  textAlign: "center",
  ...getFontStyles(theme.text.primary_font, ["color"]),

  h1: {
    color: theme.global.primary_active_color,
    fontSize: "28px",
    fontFamily: theme.secondary_font.medium.font_family,
    fontWeight: 800,
    margin: 0,

    "@media (max-width: 767px)": {
      fontSize: "24px",
    },
  },

  h2: {
    fontSize: "20px",
    margin: "0 0 30px",
    fontWeight: 100,
  },

  "p,ul": {
    fontSize: "16px",
    margin: "20px 0",
  },

  ul: {
    listStyleType: "none",
    padding: 0,
  },

  "@media (max-width: 767px)": {
    fontSize: "14px",
  },
}));
