import styled from "styled-components";
import { Image } from "@/components/image";
import { useSelector } from "@/redux";
import { getFontStyles } from "@/redux/cmsConfig/utils";
import { getS3Path } from "@/utils/s3";

export function GraetersRewardsTerms() {
  const { slug } = useSelector((state) => state.app.organization.organization);
  return (
    <ModalContent>
      <Image
        alt="Graeter's Rewards"
        height={196}
        src={getS3Path(slug, "images/how-rewards-work.png")}
        width={294}
      />
      <Header>Order Ice Cream</Header>
      <Paragraph>
        Order pints and other to-go items ahead for pick-up or get them
        delivered to you.
      </Paragraph>
      <Header>Earn Sweet Rewards</Header>
      <Paragraph>
        Track and earn points for every purchase from our retail stores, every
        100 points you&apos;ll get a $5 reward.
      </Paragraph>
      <Header>Get Offers &amp; News</Header>
      <Paragraph>
        Get the latest scoop on offers, promotions and all things
        Graeter&apos;s.
      </Paragraph>
      <Paragraph>
        For more information, please visit our&nbsp;
        <Link
          href="https://www.graeters.com/help-sweet-rewards"
          target="_blank"
          rel="noreferrer"
          aria-label="Go to Rewards page"
        >
          FAQ page
        </Link>
        .
      </Paragraph>
    </ModalContent>
  );
}

const ModalContent = styled.div(({ theme }) => ({
  margin: "20px 0 0",
  padding: "20px",
  ...getFontStyles(theme.text.primary_font, ["color"]),
}));

const Header = styled.h1`
  font-family: ${({ theme }) => theme.primary_font.bold.font_family};
  font-size: 24px;
`;

const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.primary_font.regular.font_family};
  font-size: 18px;
`;

const Link = styled.a`
  text-decoration: underline;
`;
