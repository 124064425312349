import NextImage, { type ImageLoaderProps, type ImageProps } from "next/image";
import { stringify } from "qs";
import { IMAGE_RESIZER } from "@/utils/imageHelper";

/**
 * Custom loader for next/image to optimize and
 * cache images using Koala's Image Resizer API.
 */
function loader({ width, src, quality = 85 }: ImageLoaderProps) {
  // Don't try to resize vector graphics like SVGs & PDFs
  if (src.endsWith(".svg") || src.endsWith(".pdf")) {
    return src;
  }
  // Otherwise, pass the image to the resizer.
  return `${IMAGE_RESIZER}?source=${src}&${stringify({
    format: "auto",
    quality,
    width,
  })}`;
}

/**
 * Wraps Next's image component to use the Koala Image Resizer.
 * @see https://nextjs.org/docs/api-reference/next/legacy/image
 */
export function Image(props: ImageProps) {
  return <NextImage unoptimized loader={loader} {...props} />;
}
